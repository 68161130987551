import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MarketingPage from './marketing-page/MarketingPage';
import PrivacyPolicy from './marketing-page/components/legal/PrivacyPolicy';
import TermsOfService from './marketing-page/components/legal/TermsOfService';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
