import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import AppTheme from '../../shared-theme/AppTheme';
import { db, trackEvent } from '../../lib/firebase';
import { doc, setDoc, Timestamp, FirestoreError } from 'firebase/firestore';
import Confetti from 'react-confetti-boom';

// Glowing outline effect for mockup display containers
const StyledBox = styled('div')<{ zindex?: number }>(({ theme, zindex }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: '20px',
  backgroundSize: 'cover',
  position: 'relative',
  zIndex: zindex,
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  '&::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    border: `1px solid ${(theme.vars || theme).palette.grey[200]}`,
    outline: '6px solid hsla(220, 25%, 80%, 0.2)',
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    pointerEvents: 'none',
    zIndex: -1,
    transform: 'translate(0%, -8%)',
    [theme.breakpoints.up('sm')]: {
      left: '10%',
      width: '80%',
      transform: 'translate(-12.5%, -8%)',
    },
  },
  ...theme.applyStyles('dark', {
    '&::before': {
      boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
      borderColor: (theme.vars || theme).palette.grey[700],
      outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    },
  }),
}));

// Component that renders the skewed mockups of the dashboard and matches screens
const SkewedComponent: React.FC = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Image paths based on theme
  const dashboardImage = isDarkMode ? '/imgs/dashboard_dark.png' : '/imgs/dashboard_light.png';
  const matchesImage = isDarkMode ? '/imgs/matches_dark.png' : '/imgs/matches_light.png';

  // Force re-render on mount to fix image scaling
  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  // Dashboard mockup container
  const innerDashboard =
    <StyledBox id="image" zindex={1}>
      <img
        src={dashboardImage}
        alt="Dashboard"
        style={{
          borderRadius: '20px',
          pointerEvents: 'none',
          transformOrigin: 'top left',
          transform: 'scale(0.8) translateY(-8%)', // scaling and upward adjustment
          height: '125%',
          position: 'relative',
        }}
      />
    </StyledBox>;

  // Matches mockup container
  const innerMatches =
    <StyledBox id="image" zindex={2}>
      <img
        src={matchesImage}
        alt="Matches"
        style={{
          borderRadius: '20px',
          pointerEvents: 'none',
          transformOrigin: 'top left',
          transform: 'scale(0.8) translateY(-8%)', // scaling and upward adjustment
          height: '125%',
          position: 'relative',
        }}
      />
    </StyledBox>;

  return (
    // Outer container for all mockups
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        mt: 0,
        pt: { xs: '300px', sm: '200px' },
        overflow: 'hidden',
        height: { xs: '1000px', sm: '1250px' },
      }}
    >
      {/* Container that applies skew transformation */}
      <Box
        sx={{
          position: 'absolute',
          width: '140%',
          mt: 0,
          ml: { xs: '150px', sm: '240px' }, // Increased margin on desktop
          overflow: 'hidden',
          height: '1200px',
          transform: 'skew(20deg, -3deg) scaleX(1.15)',
        }}
      >
        {/* Gradient overlay for mockup fade-out effect */}
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: { xs: '50%', sm: '20%' },
            zIndex: 10,
            pointerEvents: 'none',
            background: `linear-gradient(
              to bottom,
              rgba(0,0,0,0) 0%,
              rgba(0,0,0,0) 50%,
              ${theme.palette.background.default} 85%,
              ${theme.palette.background.default} 100%
            )`,
          })}
        />
        {/* Dashboard mockup */}
        <Box
          sx={{
            position: 'absolute',
            transformOrigin: 'top left',
            transform: {
              xs: 'translate(20px, 0px)',
              sm: 'translate(20px, 0px)', // Move dashboard right on desktop
            },
          }}
        >
          {innerDashboard}
        </Box>
        {/* Matches mockup */}
        <Box
          sx={{
            position: 'absolute',
            transformOrigin: 'top left',
            transform: {
              xs: 'translate(50px, 120px)', // Changed from 180px to 120px on mobile
              sm: 'translate(350px, 155px)', // Move matches further right on desktop
            },
          }}
        >
          {innerMatches}
        </Box>
      </Box>
    </Box>
  );
};

// Main hero section component
export default function Hero() {
  // Form state management
  const [email, setEmail] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = React.useState<{ type: 'success' | 'error' | ''; message: string }>({ type: '', message: '' });
  const buttonRef = React.useRef<HTMLButtonElement>(null); // Reference for confetti positioning

  // Handle email submission and Firebase interaction
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setSubmitStatus({ type: 'error', message: 'Please enter a valid email address' });
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });
    try {
      const emailDoc = doc(db, 'waitlist', email.toLowerCase());
      await setDoc(emailDoc, {
        email: email.toLowerCase(),
        timestamp: Timestamp.now(),
        source: window.location.hostname
      });
      
      trackEvent('collected_email', {
        source: window.location.hostname
      });

      setSubmitStatus({
        type: 'success',
        message: 'Thanks for joining! We\'ll be in touch soon.'
      });
      setEmail('');
    } catch (error) {
      console.error('Error adding email:', error);
      if ((error as FirestoreError).code === 'permission-denied') {
        setSubmitStatus({
          type: 'success',
          message: 'You\'re already in!'
        });
        setEmail('');
      } else {
        setSubmitStatus({
          type: 'error',
          message: 'Something went wrong. Please try again.'
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    // Main hero section container with background gradient
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          zIndex: '5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        {/* Content stack: heading, subheading, and form */}
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' }, zIndex: 10}}
        >
          {/* Main heading with highlight */}
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: { xs: 'clamp(1rem, 10vw, 3.5rem)', sm: 'clamp(3rem, 10vw, 3.5rem)' },
            }}
          >
            Unite&nbsp;innovators,&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              ignite&nbsp;ideas.&nbsp;
            </Typography>
          </Typography>
          {/* Subheading text */}
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Connect with your university's entrepreneurship community to find co-founders, collaborators, or student-led startups at your campus.
          </Typography>
          {/* Email collection form */}
          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '350px' }}>
            {/* Form input container */}
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: '100%', maxWidth: '350px', zIndex: 5 }}
            >
              <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email-hero"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="you@university.edu"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting}
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                  },
                }}
              />
              <Button
                ref={buttonRef}
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                sx={{ minWidth: 'fit-content' }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Joining...' : 'Join the waitlist'}
              </Button>
            </Stack>
            {/* Status message display */}
            {submitStatus.message && (
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  color: submitStatus.type === 'error' ? 'error.main' : 'success.main',
                }}
              >
                {submitStatus.message}
              </Typography>
            )}
            {/* Success animation */}
            {submitStatus.type === 'success' && buttonRef.current && (
              <Confetti
                mode="boom"
                x={buttonRef.current.offsetLeft / window.innerWidth}
                y={buttonRef.current.offsetTop / window.innerHeight}
                particleCount={50}
                colors={['#ff577f', '#ff884b', '#ffd384', '#fff9b0']}
                effectCount={1}
                effectInterval={3000}
              />
            )}
          </form>
        </Stack>
        {/* Mockups display */}
        <SkewedComponent />
        {/* Bottom spacing */}
        <Box sx={{ height: { xs: '525px', sm: '900px', md: '825px' } }} />
      </Container>
    </Box>
  );
}
