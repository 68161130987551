import { Container, Typography, Box, List, ListItem } from '@mui/material';
import AppAppBar from '../AppAppBar';
import Footer from '../footer';
import { Divider } from '@mui/material';
import AppTheme from '../../../shared-theme/AppTheme';

export default function PrivacyPolicy() {
  return (
    <AppTheme>
      <AppAppBar />
      <Box sx={{ height: '100px' }} />
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect information that you provide directly to us, including:
          </Typography>
          <List>
            <ListItem>• Your name, email address, and university affiliation</ListItem>
            <ListItem>• Profile information and preferences</ListItem>
            <ListItem>• Communications and interactions within the platform</ListItem>
            <ListItem>• Academic and professional interests</ListItem>
          </List>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use the collected information to:
          </Typography>
          <List>
            <ListItem>• Provide and improve our co-founder matching services</ListItem>
            <ListItem>• Verify your university affiliation</ListItem>
            <ListItem>• Protect the security of our platform</ListItem>
            <ListItem>• Send important updates and communications</ListItem>
          </List>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            3. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            4. Your Rights
          </Typography>
          <Typography paragraph>
            You have the right to:
          </Typography>
          <List>
            <ListItem>• Access your personal information</ListItem>
            <ListItem>• Correct inaccurate data</ListItem>
            <ListItem>• Request deletion of your data</ListItem>
            <ListItem>• Opt-out of communications</ListItem>
          </List>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            5. Contact Us
          </Typography>
          <Typography paragraph>
            For privacy-related questions, please contact us at privacy@aventre.io
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Footer />
    </AppTheme>
  );
}
