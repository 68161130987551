import { Container, Typography, Box, List, ListItem } from '@mui/material';
import AppAppBar from '../AppAppBar';
import Footer from '../footer';
import { Divider } from '@mui/material';
import AppTheme from '../../../shared-theme/AppTheme';

export default function TermsOfService() {
  return (
    <AppTheme>
      <AppAppBar />
	  <Box sx={{ height: '100px' }} />
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Eligibility
          </Typography>
          <Typography paragraph>
            To use Aventre, you must:
          </Typography>
          <List>
            <ListItem>• Be enrolled at a recognized university</ListItem>
            <ListItem>• Have a valid university email address</ListItem>
            <ListItem>• Be at least 18 years old</ListItem>
            <ListItem>• Comply with our community guidelines</ListItem>
          </List>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            2. User Conduct
          </Typography>
          <Typography paragraph>
            You agree not to:
          </Typography>
          <List>
            <ListItem>• Misrepresent your identity or affiliations</ListItem>
            <ListItem>• Share inappropriate or harmful content</ListItem>
            <ListItem>• Harass or discriminate against other users</ListItem>
            <ListItem>• Use the platform for commercial spam</ListItem>
          </List>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            3. Intellectual Property
          </Typography>
          <Typography paragraph>
            Users retain rights to their content but grant Aventre a license to use, modify, and display content shared on the platform. Respect others' intellectual property rights.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            4. Termination
          </Typography>
          <Typography paragraph>
            We reserve the right to suspend or terminate accounts that violate these terms or engage in inappropriate behavior. Users may delete their accounts at any time.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            5. Disclaimer
          </Typography>
          <Typography paragraph>
            Aventre is provided "as is" without warranties. We're not responsible for user-generated content or disputes between users.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            6. Changes to Terms
          </Typography>
          <Typography paragraph>
            We may modify these terms at any time. Continued use of Aventre constitutes acceptance of updated terms.
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Footer />
    </AppTheme>
  );
}
