import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SyncrLogo from '../../sharedComponents/syncrLogo';
import { db, trackEvent } from '../../lib/firebase';
import { doc, setDoc, Timestamp, FirestoreError } from 'firebase/firestore';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="/">
        Aventre
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const [email, setEmail] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = React.useState<{ type: 'success' | 'error' | ''; message: string }>({ type: '', message: '' });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setSubmitStatus({ type: 'error', message: 'Please enter a valid email address' });
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });
    try {
      const emailDoc = doc(db, 'waitlist', email.toLowerCase());
      await setDoc(emailDoc, {
        email: email.toLowerCase(),
        timestamp: Timestamp.now(),
        source: window.location.hostname
      });
      
      trackEvent('collected_email', {
        source: window.location.hostname
      });

      setSubmitStatus({
        type: 'success',
        message: 'Thanks for joining!'
      });
      setEmail('');
    } catch (error) {
      console.error('Error adding email:', error);
      if ((error as FirestoreError).code === 'permission-denied') {
        setSubmitStatus({
          type: 'success',
          message: 'You\'re already in!'
        });
        setEmail('');
      } else {
        setSubmitStatus({
          type: 'error',
          message: 'Something went wrong. Please try again.'
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        py: 8,
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
		  	<SyncrLogo size='30px' padding='5px' marginLeft='10px'/>
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
              Join the newsletter
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Subscribe for the latest updates to Aventre.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack direction="row" spacing={1} useFlexGap>
                <TextField
                  id="email-newsletter"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isSubmitting}
                  aria-label="Enter your email address"
                  placeholder="Your email address"
                  slotProps={{
                    htmlInput: {
                      autoComplete: 'off',
                      'aria-label': 'Enter your email address',
                    },
                  }}
                  sx={{ width: '250px' }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ flexShrink: 0 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Joining...' : 'Subscribe'}
                </Button>
              </Stack>
              {submitStatus.message && (
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    display: 'block',
                    color: submitStatus.type === 'error' ? 'error.main' : 'success.main',
                  }}
                >
                  {submitStatus.message}
                </Typography>
              )}
            </form>
          </Box>
        </Box>
		<div>
          <Link color="text.secondary" variant="body2" href="/privacy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms">
            Terms of Service
          </Link>
		  <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
		  <Link color="text.secondary" variant="body2" href="mailto:info@aventre.io">
            Contact Us
          </Link>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: 4,
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Copyright />
      </Box>
    </Container>
  );
}