import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import FAQ from './components/FAQ';
import AppTheme from '../shared-theme/AppTheme';
import Footer from './components/footer';
import { Divider } from '@mui/material';

export default function MarketingPage(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />

      <AppAppBar />
      <Hero />
      <div>
        <FAQ/>
        <Divider/>
        <Highlights />
      </div>
      <Divider/>
      <Footer/>
    </AppTheme>
  );
}
